<template>
  <div
    v-if="article"
    class="flex flex-col w-full mt-4"
  >
    <h3 class="mx-6 text-2xl font-bold leading-7">
      {{ article.title }}
    </h3>
    <div
      v-if="article.internal"
      class="flex flex-col mx-6 mt-1 text-sm font-bold text-red-500"
    >
      内部资料不对外发布
    </div>
    <div
      v-else
      class="flex flex-col mx-6 mt-1"
    >
      <div
        v-if="article.authorizationRequired"
        class="flex items-center mt-2 text-sm font-semibold text-red-500"
      >
        仅限业主访问
      </div>
      <div class="flex items-center mt-2 text-sm text-gray-500">
        发布时间：{{
          $dayjs(article.publishedAt || article.published_at).format('YYYY-MM-DD HH:mm:ss')
        }}
      </div>
      <div class="flex items-center mt-2 text-sm text-gray-500">
        类别：{{ article.category }}
      </div>
    </div>
    <div
      v-if="!article.type || article.type == 'HTML'"
      :inner-html.prop="article.content | htmlXss"
      class="w-full braft-output-content"
      :class="article.contentClassname ? article.contentClassname : 'p-6'"
    />
    <div
      v-if="article.type == 'Markdown'"
      :inner-html.prop="markdown"
      class="w-full heiti content-container"
      :class="
        article.contentClassname ? article.contentClassname : 'p-6 text-justify'
      "
    />
    <van-empty
      v-if="pdf"
    >
      <template #image>
        <img
          src="../../assets/images/file/pdf.png"
          style="width: 96px; height: 96px;"
          class="mx-auto mt-10"
        >
      </template>
      <template #description>
        <span class="text-sm">该内容为PDF格式，请点击下方按钮打开</span>
      </template>
      <van-button
        round
        plain
        type="danger"
        class="bottom-button"
        @click="openPdf()"
      >
        点击阅读全文
      </van-button>
    </van-empty>
    <van-empty
      v-if="article.type == 'Link'"
    >
      <template #image>
        <img
          src="../../assets/images/file/web.png"
          style="width: 96px; height: 96px;"
          class="mx-auto mt-10"
        >
      </template>
      <template #description>
        <span class="text-sm">该链接为外部页面，确定要打开吗？</span>
      </template>
      <van-button
        round
        plain
        type="danger"
        class="bottom-button"
        @click="openLink()"
      >
        打开链接
      </van-button>
    </van-empty>
    <div
      v-if="article.attachments"
      class="w-full content-container"
    >
      <ul
        v-if="Array.isArray(article.attachments)"
        class="px-6 pb-6 text-sm"
      >
        <li
          v-for="(item, index) of article.attachments"
          :key="item"
          class="my-1"
        >
          <a
            :href="
              link(
                item,
                article.attachment_names
                  ? article.attachment_names[index]
                  : null
              )
            "
          >
            {{
              article.attachment_names && article.attachment_names[index]
                ? article.attachment_names[index]
                : `附件（${index + 1}）`
            }}
          </a>
        </li>
      </ul>
      <ul
        v-else
        class="px-6 pb-6 text-sm"
      >
        <li class="my-1">
          <a
            :href="
              link(
                article.attachments,
                article.attachment_names ? article.attachment_names[0] : null
              )
            "
          >
            {{
              article.attachment_names && article.attachment_names[0]
                ? article.attachment_names[0]
                : `附件`
            }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
export default {
  name: 'ComponentArticleBasic',
  props: {
    module: {
      type: String,
      default: 'public'
    },
    article: {
      type: Object,
      default: null
    }
  },
  computed: {
    markdown() {
      if (!this.article?.markdown) {
        return null
      }
      let mdi = new MarkdownIt()
      return mdi.render(this.article.markdown)
    },
    link() {
      return (e, name) => {
        if (!e) {
          return ''
        }
        let url = e.substring(0, e.indexOf('?'))
        return url.endsWith('.pdf')
          ? `/pdfjs/web/viewer.html?file=${url}${
              name ? `&name=${encodeURIComponent(name)}` : ''
            }`
          : e
      }
    },
    pdf() {
      if (this.article?.type === 'PDF' && this.article?.file) {
        const url = `/pdfjs/web/viewer.html?file=${
          this.article.file.indexOf('?') > 0
            ? this.article.file.substring(0, this.article.file.indexOf('?'))
            : this.article.file
        }&name=${encodeURIComponent(this.article.title)}`
        return url
      }
      return null
    }
  },
  methods: {
    openPdf() {
      window.location.href = this.pdf
    },
    openLink() {
      window.location.href = this.article.link
    }
  }
}
</script>

<style lang="scss">
.content-container {
  p {
    text-align: justify;
    margin-bottom: 10px;
  }
  ol {
    list-style: decimal;
    padding-left: 15px;
    margin-left: 30px;
    margin-bottom: 15px;
  }
  ul {
    list-style: initial !important;
    padding-left: 15px;
    margin-left: 30px;
    margin-bottom: 15px;
  }
}
</style>
